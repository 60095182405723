.stationary-emissions {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .legend {
      display: flex;
      align-items: center;
      margin-right: 16px;
      img {
        width: 6px;
        height: 6px;
        margin-right: 7px;
      }
    }
  }
  &__bar-chart {
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 16px 10px 16px 22px;
    border-radius: 8px;

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 14px;
      .facility-name {
        width: 80px;
      }
      .bar-wrapper {
        margin-left: 10px;
        margin-right: 21px;
        justify-self: flex-end;
      }
    }
    &__item:last-child {
      margin-bottom: 0;
    }
  }
}

.smaller {
  .rc-table-cell {
    padding: 0.75rem 0.33rem;
  }
}
